import { defineAsyncComponent } from 'vue';
// Actions
export { default as OActionSave } from './Actions.Save.vue';
export { default as OActionDelete } from './Actions.Delete.vue';
export { default as OActionMarkAsDeleted } from './Actions.MarkAsDeleted.vue';
export { default as OActionCancel } from './Actions.Cancel.vue';

export { default as OActionConfirm } from './Actions.Confirm.vue';
export { default as OActionSaveCancel } from "./Actions.SaveCancel.vue";

export { default as OAutoComplete } from './AutoComplete.AutoComplete.vue';

export { default as ORelatedItems } from "./RelatedItems.RelatedItems.vue";
export { default as ORelatedItem } from "./RelatedItems.RelatedItem.vue";
export { default as ORelatedItemButton } from "./RelatedItems.RelatedItemButton.vue";
export { default as ORelatedItemInput } from "./RelatedItems.RelatedItemInput.vue";
export { default as ORelatedCheckbox } from "./RelatedItems.RelatedCheckbox.vue";

export { getLayoutsDataObject } from "./Layouts.helpers.ts";

export { default as OSignatures } from "./Signatures.Signatures.vue";

export const OPropertiesGrid = defineAsyncComponent(() => import('./PropertiesGrid.PropertiesGrid.vue').then(m => m.default));
export const OPropertiesItem = defineAsyncComponent(() => import('./PropertiesGrid.PropertiesItem.vue').then(m => m.default));
export const OPropertiesGroup = defineAsyncComponent(() => import('./PropertiesGrid.PropertiesGroup.vue').then(m => m.default));

export const OFieldsGrid = defineAsyncComponent(() => import('./FieldsGrid.FieldsGrid.vue').then(m => m.default));

export { default as OFullTextSearch } from './FullTextSearch.vue';

export const OJournal = defineAsyncComponent(() => import('./Journal.vue').then(m => m.default));

export const OImageBrowser = defineAsyncComponent(() => import('./ImageBrowser.vue').then(m => m.default));

export const OCheckItems = defineAsyncComponent(() => import('./CheckItems.CheckItems.vue').then(m => m.default));

export const OFrequencyEditor = defineAsyncComponent(() => import('./FrequencyEditor.FrequencyEditorModal.vue').then(m => m.default));
export const OFrequencyEditorForm = defineAsyncComponent(() => import('./FrequencyEditor.FrequencyEditor.vue').then(m => m.default));

export const OJSONForm = defineAsyncComponent(() => import('./JSON.Form.vue').then(m => m.default));

export const OLayoutsList = defineAsyncComponent(() => import('./Layouts.List.vue').then(m => m.default));

export const OAppReports = defineAsyncComponent(() => import('./AppReports.AppReports.vue').then(m => m.default));